/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from "react";
import React from "react";
import "./styles/index.css";

export interface Grade {
  date: string;
  name: string;
  value?: number;
  multiplier?: string;
  details: string;
  average?: number;
}

interface focusData extends data {
  visible: boolean;
}
export interface data {
  isSkeleton: boolean;
  code: string;
  name: string;
  average: number;
  grades: Grade[];
}

const App = () => {
  const [data, setData] = useState<data[] | undefined>(undefined);
  const [focusData, setFocusData] = useState<focusData | undefined>(undefined);
  useEffect(() => {
    const cached = window.localStorage.getItem("data-cache");
    if (cached)
      setData(
        JSON.parse(cached).map((d: data) => ({ ...d, isSkeleton: true }))
      );

    fetch("https://grades.janic.io/", { method: "GET" }).then((response) => {
      response.json().then((data) => {
        setData(data);
        window.localStorage.setItem("data-cache", JSON.stringify(data));
      });
    });
  }, []);
  if (data) {
    return (
      <div
        className={
          "dark:text-white text-white p-8 md:p-10 xl:p-20 " +
          (focusData?.visible ? "h-screen overflow-hidden" : "")
        }
      >
        <div
          onClick={(e) => {
            if ((e.target as unknown as { id: string }).id === "modal-bg") {
              if (focusData) setFocusData({ ...focusData, visible: false });
              else setFocusData(undefined);
            }
          }}
          id="modal-bg"
          className={
            "absolute w-full h-full z-10 left-0 transition-transform top-0 flex justify-center items-center backdrop-blur-lg " +
            (focusData?.visible ? "scale-100" : "scale-0")
          }
        >
          <div className="p-6 w-full rounded md:w-1/2 bg-slate-500">
            <h1 className="text-3xl">{focusData?.code}</h1>
            <h1>{focusData?.name}</h1>
            {focusData?.average ? (
              <div
                className={
                  "text-center mt-5 p-4 rounded " +
                  (focusData.average
                    ? focusData.average > 5
                      ? "bg-green-500"
                      : focusData.average > 4
                      ? "bg-orange-300"
                      : "bg-red-500"
                    : "bg-slate-800")
                }
              >
                {focusData?.average}
              </div>
            ) : null}
            {focusData?.grades.length ? (
              <table className="mt-5 w-full">
                <thead>
                  <tr>
                    <td className="hidden md:block">Datum</td>
                    <td>Topic</td>
                    <td>Grade</td>
                    <td className="hidden md:block">Durchschnitt</td>
                    <td>Weight</td>
                  </tr>
                </thead>
                <tbody>
                  {focusData.grades.map((item, i) => (
                    <tr key={i} className="">
                      <td className="hidden md:block">{item.date}</td>
                      <td>{item.name}</td>
                      <td>{item.value}</td>
                      <td className="hidden md:block">{item.average}</td>
                      <td>{item.multiplier}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="p-4 mt-4 text-center bg-gray-600 rounded">
                no grades yet
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          {data.map((d, i) => {
            return (
              <div
                onClick={() =>
                  isNaN(d.average) || d.isSkeleton
                    ? ""
                    : setFocusData({ ...d, visible: true })
                }
                className={"plausible-event-name=Click+Subject plausible-event-subject=" + d.code.replace(" ", "") + " " + (
                  d.isSkeleton
                    ? "p-6 bg-slate-200 dark:bg-slate-800 rounded w-full md:w-fit"
                    : "p-6 rounded translate hover:shadow-slate-300 dark:hover:shadow-black duration-150 transition-all w-full md:w-fit " +
                      (d.grades.length > 0
                        ? " cursor-pointer hover:shadow-2xl motion-safe:hover:-translate-y-1 "
                        : "") +
                      (d.average
                        ? d.average > 5
                          ? "bg-green-500"
                          : d.average > 4
                          ? "bg-orange-400"
                          : "bg-red-500"
                        : "bg-slate-700"))
                }
                key={i}
              >
                <h1 style={{ opacity: d.isSkeleton ? 0 : 1 }}>{d.name}</h1>
                <p
                  style={{ opacity: d.isSkeleton ? 0 : 1 }}
                  className="text-slate-800 dark:text-slate-200"
                >
                  {d.code}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default App;
